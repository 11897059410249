.App {
  text-align: center;
}

.App-logo {
  width: 250px;
  pointer-events: none;
}

.App-header {
  background-color: #000;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 50px;
}

.App-link {
  color: #61dafb;
}

p {
  color: #a7a7a7;
  text-align: center;
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.App-icon {
  margin: 10px;
}

.App-icon:hover path {
  fill: #ffffff;
}
